//site
$primary-h: 160 !default;
$primary-s: 100 !default;
$primary-b: 48 !default;

$unione: #007a52;

$primary: $unione;
$secondary: #30373d;
$tertiary: #5a6771;
$tertiary-text: white;

$header-slim-bg-color: $secondary;
$header-center-bg-color: #fff;

//$headings-color: $primary;
//$semibold: 600;
