$footer-bg-color: $primary-a11;

.it-brand-text {
  h2 {
    &:before {
      content: 'Unione Comuni';
      display: block;
      font-weight: 400;
      font-size: 0.875em;
    }
  }
}
